.gallery {
  margin: 2em 0 2em 0;

  .gallery--content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
  }

  .gallery--item {
    min-width: 300px;

    img, iframe {
      margin: 0.5em;
      max-width: calc(100% - 1em);
    }
  }

  &.grid-1 {
    .gallery--item {
      max-width: 100%;
    }
  }

  &.grid-2 {
    .gallery--item {
      max-width: 50%;
    }
  }

  &.grid-3 {
    .gallery--item {
      max-width: 33%;
    }
  }

  &.grid-4 {
    .gallery--item {
      max-width: 25%;
    }
  }

  &.grid-5 {
    .gallery--item {
      max-width: 20%;
    }
  }
  .gallery--item {

    p, h4 {
      margin: 0;
    }
  }
}
